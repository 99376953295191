
import { authMapActions, authMapState } from "@/store/modules/auth";
import {
  appMapActions,
  appMapMutations,
  appMapState
} from "@/store/modules/app";
import * as dateFns from "date-fns";

import ApplicationWrapWithHeader from "@/views/wrappers/ApplicationWrapWithHeader.vue";
import ApplicationWrapWithoutHeader from "@/views/wrappers/ApplicationWrapWithoutHeader.vue";
import AppLoading from "@/views/public/Loading.vue";
import AgencyCodeSwitcher from "@/components/User/AgencyCodeSwitcher.vue";

import { kebabCase, throttle } from "lodash";
import Vue from "vue";
import { FEATURE_FLAG_QUOTES } from "./helpers/features";
import { userMapActions } from "./store/modules/user";
import { IUserModel } from "@/types/interfaces/user";
import VueWithMixins from "./helpers/mixins";
import LiveChatMixin from "./components/LiveChatMixin";
import { isExemptedUserRole } from "./helpers";

const { VUE_APP_ENV = "" } = process.env;

export default VueWithMixins(LiveChatMixin).extend({
  name: "App",
  components: {
    AppLoading,
    AgencyCodeSwitcher
  },
  data(): any {
    return {
      loadingVisibilityCheck: false,
      loadingFullApp: true,
      intervalId: 0,
      showingInactivityPrompt: false,
      modalTitle: "",
      modalDescription: "",
      modalButtons: [],
      agencySwitchData: null
    };
  },
  created(): void {
    this.appHandleResize();
    const debouncedResize = throttle(this.appHandleResize, 1000) as any;
    const debouncedLastActivity = throttle(this.appLastActivity, 10000) as any;
    window.addEventListener("resize", debouncedResize);
    window.addEventListener("mouseover", debouncedLastActivity);
    window.addEventListener("keypress", debouncedLastActivity);

    this.$Progress.start();
    this.$router.beforeEach((to: any, from: any, next: any) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        (this.$Progress as any).parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
      this.$nextTick(() => {
        this.loadingFullApp = false;
      });
    });
    this.intervalId = setInterval(this.idleTimeCheckHandler, 10000);
  },
  mounted(): void {
    this.initAppTheme();
    const { appInnerView } = this.$refs as any;
    if (appInnerView) {
      const debounced = throttle(this.appHandleScroll, 200) as any;
      appInnerView.addEventListener("scroll", debounced);
    }
    document.addEventListener("visibilitychange", this.doVisibilityCheck);
  },
  destroyed() {
    const debounced = throttle(this.appHandleResize, 1000) as any;
    window.removeEventListener("resize", debounced); //WOW - why was I doing this. I just thought we have nothing to do after removing an event listener
    window.removeEventListener("mouseover", () => {});
    window.removeEventListener("keypress", () => {});
    document.removeEventListener("visibilitychange", this.doVisibilityCheck);
    clearInterval(this.intervalId);
  },
  computed: {
    ...authMapState(["initData", "hasValidLicense"]),
    ...appMapState([
      "showUnreadNotificationPrompt",
      "routeRendered",
      "isResizing",
      "showLoggedOutModal"
    ]),
    maintenanceModeString(): string {
      return this.$isInEmergencyMaintenanceMode
        ? "MAINTENANCE MODE"
        : this.$isInFiservScheduledMaintenanceMode
        ? "FISERV MAINTENANCE MODE"
        : "MODE";
    },
    layoutComponent(): any {
      //This is causing re-rendering(re-mount) of pages TODO: discuss
      // if (this.isResizing) {
      //   return "div";
      // }
      const { meta = {} } = this.$route;
      switch (meta.layout) {
        case "no-header":
          return ApplicationWrapWithoutHeader;
        default:
          return ApplicationWrapWithHeader;
      }
    },
    appContainerHeight(): number {
      return this.$getWindowSize.height;
    },
    pageClass(): string {
      const title = this.$route.meta.pageTitle || "unknown";
      return `page-${kebabCase(title)}`;
    },
    emergencyMaintenanceModeClass(): string {
      return this.$isInEmergencyMaintenanceMode ? "in-maintenance-mode" : "";
    },
    fiservScheduledMaintenanceModeClass(): string {
      return this.$isInFiservScheduledMaintenanceMode
        ? "in-fiserv-maintenance-mode"
        : "";
    },
    isOnboarding(): boolean {
      return this.$route.path.includes("onboarding");
    },
    quoteFeatureEnabled(): boolean {
      const { disabledFeatures = [] } = this.$getCurrentUser || {};
      if (disabledFeatures && Array.isArray(disabledFeatures)) {
        const check = disabledFeatures.find(
          (dsf: any) => dsf.key === FEATURE_FLAG_QUOTES
        );
        return !check;
      }
      return true;
    },
    notificationModalNotShown(): boolean {
      return (
        (this.$canReadNotifications &&
          this.initData?.urgentUnreadNotifications.length < 1) ||
        !this.$canReadNotifications
      );
    },
    urgentNotificationModalButtons(): any[] {
      return [
        {
          text: `View Notifications`,
          classList: [""],
          click: () => this.goToUserNotifications()
        }
      ];
    },
    updateAgencyModalButtonsForAgencyAdmins(): any[] {
      return [
        {
          text: `Make Updates`,
          classList: [""],
          click: () => this.goToEditAgency()
        }
      ];
    },
    updateAgencyModalButtons(): any[] {
      return [
        {
          text: `Logout`,
          classList: [""],
          click: () => {
            this.resetModal();
            this.logout();
          }
        }
      ];
    },
    licenseExpirationModalButtons(): any[] {
      return [
        {
          text: `Update License`,
          classList: [""],
          click: () => this.goToUpdateLicense()
        }
      ];
    },
    timeToLogOutOfEmergencyMode() {
      const duration = dateFns.intervalToDuration({
        start: Date.now(),
        end: dateFns.addSeconds(Date.now(), this.secondsToLogout)
      });
      return dateFns.formatDuration(duration, {
        delimiter: ", "
      });
    }
  },
  watch: {
    $route(to, from) {
      if (from.path && to.path && from.path === to.path) return;
      if ((to && to.name) || to.path) {
        if (this.$isLoggedIn) {
          this.makeScreenViewActivity({ screenName: to.name || to.path });
        }
      }
      this.maybeOpenUnreadNotificationsModal();
      this.maybeOpenSetAgencyAddressModal();
      this.mayBeOpenLicenseExpirationModal();
    },
    showLoggedOutModal: {
      handler(showLoggedOutValue: boolean) {
        if (showLoggedOutValue) {
          this.authenticationLogoutModal();
        }
      },
      immediate: false
    },
    $isLoggedIn(isLoggedIn: boolean) {
      if (!isLoggedIn) {
        this.$socket.client.close();
        this.$router
          .replace({
            path: "/login",
            query: { redirectFrom: this.$route.path }
          })
          .catch(() => {});
        this.destroyChat();
      } else {
        //Opens websocket connection for every logged in user
        //I always wondered whether this is better done on views that
        //actually use the websocket.
        this.$socket.client.open();
      }
    },
    $getCurrentUser: {
      handler(user: IUserModel) {
        //disabling it for dev cos test users keep showing up on live chat. atlas complaints
        if (!["development", "staging", "ezlynx"].includes(VUE_APP_ENV)) {
          this.setUpChatLive(user);
        }
      },
      deep: true
    },
    $windowVisible(isWindowVisible: boolean, oldValue: boolean) {
      const TIMEOUT_FOR_WINDOW_VISIBILITY_REFRESH = 60; // Seconds
      this.loadingVisibilityCheck = false;
      const { requireAuth } = this.$route.meta;
      if (requireAuth && !oldValue && isWindowVisible) {
        const lastActivityMustBeAfter = dateFns.addSeconds(
          this.$lastActivity,
          TIMEOUT_FOR_WINDOW_VISIBILITY_REFRESH
        );

        if (dateFns.isAfter(new Date(), lastActivityMustBeAfter)) {
          this.loadingVisibilityCheck = true;
          this.authCheckGetUser().finally(() => {
            this.loadingVisibilityCheck = false;
          });
        }
      }
    },
    "initData.isInEmergencyMaintenanceMode": {
      handler(isInEmergencyMaintenanceMode: boolean) {
        if (isInEmergencyMaintenanceMode) {
          this.doMaintenanceLogout(
            this.initData.emergencyMaintenanceModeMessage
          );
        }
      },
      immediate: false
    },
    "initData.isInFiservScheduledMaintenanceMode": {
      handler(isInFiservScheduledMaintenanceMode: boolean) {
        if (isInFiservScheduledMaintenanceMode) {
          this.doMaintenanceLogout(
            this.initData.fiservScheduledMaintenanceModeMessage
          );
        }
      },
      immediate: false
    },
    initData: {
      immediate: true,
      deep: true,
      handler() {
        this.maybeOpenUnreadNotificationsModal();
      }
    }
  },
  methods: {
    ...userMapActions(["makeScreenViewActivity"]),
    ...authMapActions(["authCheckGetUser", "logout"]),
    ...appMapMutations(["SET_WINDOW_VISIBILITY"]),
    ...appMapActions([
      "appHandleResize",
      "appHandleScroll",
      "refreshItemStore",
      "appLastActivity"
    ]),
    handleAgencyCodeSwitching($event: any) {
      this.modalButtons = [
        { text: `Continue`, classList: [""], click: () => this.switchAgency() },
        {
          text: `Cancel`,
          classList: ["bg-red-600"],
          click: () => this.closeAgencySwitchingModal("closeModal")
        }
      ];
      this.agencySwitchData = $event;
    },
    async switchAgency() {
      await this.agencySwitchData.updateUserAgentCode();
      this.closeAgencySwitchingModal();
    },
    closeAgencySwitchingModal(action: "closeModal" | "both" = "both") {
      this.$modal.hide("show-agency-switch-alert");
      if (action !== "closeModal") {
        this.$router.go();
      }
    },
    doInactivityLogout() {
      this.hideInactivityModal();
      this.logout();
      this.$modal.hide("app-modal");
    },
    hideInactivityModal() {
      this.showingInactivityPrompt = false;
      this.$modal.hide("inactivity-modal");
    },
    async inactivityPrompt(): Promise<any> {
      if (!this.showingInactivityPrompt) {
        this.showingInactivityPrompt = true;
        this.$modal.show("inactivity-modal");
        this.appLastActivity();
      }
    },
    scrollAppToTop(): void {
      const { appInnerView } = this.$refs as any;
      if (appInnerView) {
        appInnerView.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    scrollAppToCenter(): void {
      const { appInnerView } = this.$refs as any;
      if (appInnerView) {
        appInnerView.scrollTo({
          top: appInnerView.scrollTop / 2,
          behavior: "smooth"
        });
      }
    },
    doVisibilityCheck(): void {
      const { hidden } = document;
      this.SET_WINDOW_VISIBILITY(!hidden);
    },
    initAppTheme(): void {
      const { logoIconUrl } = this.$getAppTheme;
      if (logoIconUrl && logoIconUrl.length > 0) {
        const faviconLink: any = document.getElementById("app-favicon-link");
        faviconLink.href = logoIconUrl;
      }
    },
    goToUserNotifications() {
      if (this.$isCurrentUserAdmin) {
        this.$router.push("/admin/notifications");
      } else {
        this.$router.push("/notifications");
      }
      this.resetModal();
    },
    goToEditAgency() {
      this.$router.push("/agencyAdmin/agency");
      this.resetModal();
    },
    goToUpdateLicense() {
      this.$router.push("/profile");
      this.resetModal();
    },
    idleTimeCheckHandler() {
      const maxInactivityWaitTime = 10;
      const maxInactivityTimeToLogout = maxInactivityWaitTime * 2;
      const lastActivityDuration = dateFns.differenceInMinutes(
        new Date(),
        this.$lastActivity
      );
      if (lastActivityDuration >= maxInactivityWaitTime) {
        if (this.$isLoggedIn && !this.showingInactivityPrompt) {
          this.inactivityPrompt();
        }
        if (this.showingInactivityPrompt) {
          if (lastActivityDuration >= maxInactivityTimeToLogout) {
            this.hideInactivityModal(); //to close the session continuation prompt. May be you can do better?
            window.location.reload();
            this.logout();
          }
        }
      }
    },
    maybeOpenUnreadNotificationsModal() {
      if (
        this.$isLoggedIn &&
        !isExemptedUserRole(this.$getCurrentUser.role.toLowerCase()) &&
        this.$canReadNotifications &&
        this.routeRendered &&
        this.initData &&
        this.initData.urgentUnreadNotifications.length > 0 &&
        this.$getCurrentUser.status !== "pendingTutorial" &&
        !this.$route.path.includes("/notifications") &&
        !this.$route.path.includes("/profile")
      ) {
        this.modalButtons = this.urgentNotificationModalButtons;
        this.modalTitle = "You have some urgent unread notifications.";
        this.modalDescription =
          "Click the button below to view your notifications.";
        this.$modal.show("app-modal");
        return;
      }
    },
    maybeOpenSetAgencyAddressModal() {
      const rolesToCheck = ["agent", "agencyadmin", "subadmin"];
      if (
        this.$isLoggedIn &&
        this.routeRendered &&
        this.$getCurrentUser.agencyData &&
        this.notificationModalNotShown &&
        rolesToCheck.includes(this.$getCurrentUser.role.toLowerCase()) &&
        this.$getCurrentUser.status !== "pendingTutorial" &&
        !this.$getCurrentUser.agencyData.agencyAdminHasVerifiedData &&
        !this.$route.path.includes("/login") &&
        !this.$route.path.includes("/agencyAdmin/agency")
      ) {
        this.modalTitle = "Your agency requires updates to some of its data.";
        if (this.$getCurrentUser.role === "agencyAdmin") {
          this.modalButtons = this.updateAgencyModalButtonsForAgencyAdmins;
          this.modalDescription =
            "Click the button below to make these updates.";
        } else {
          this.modalButtons = this.updateAgencyModalButtons;
          this.modalDescription =
            "Please come back later or contact your agency administrator to make these updates.";
        }
        this.$modal.show("app-modal");
        return;
      }
    },
    mayBeOpenLicenseExpirationModal() {
      if (
        this.$isLoggedIn &&
        this.routeRendered &&
        this.notificationModalNotShown &&
        this.$getCurrentUser &&
        this.$getCurrentUser.status !== "pendingTutorial" &&
        this.hasValidLicense === false &&
        !this.$route.path.includes("/login") &&
        !this.$route.path.includes("/profile") &&
        !this.$route.path.includes("/onboarding")
      ) {
        this.modalButtons = this.licenseExpirationModalButtons;
        this.modalTitle = "Invalid License Information";
        this.modalDescription =
          "You need a valid agent license information to use this site. Please click the button below to update your license or contact an administrator for further assistance.";
        this.$modal.show("app-modal");
      }
    },
    resetModal() {
      this.modalButtons = [];
      this.modalTitle = "";
      this.modalDescription = "";
      this.$modal.hide("app-modal");
    },
    authenticationLogoutModal() {
      this.modalButtons = [
        {
          text: `Okay`,
          classList: [""],
          click: () => this.doInactivityLogout()
        }
      ];
      this.modalTitle = `Password sharing detected`;
      this.modalDescription = `Another user in your agency ${this
        .$getCurrentUser?.agentCode ||
        ""} has logged in with this account. Only one user can be logged in to the account at a time. You've therefore been have been logged out. If you haven't shared your credentials with anyone in your agency, please reach out to an admin immediately`;
      this.$modal.show("app-modal");
    },
    doMaintenanceLogout(emergencyPageMessage: string) {
      if (this.$isCurrentUserAdmin) {
        return;
      }
      this.logout().finally(() => {
        this.$router.push({
          path: "/emergency-maintenance",
          query: {
            message: emergencyPageMessage
          }
        });
      });
    }
  },
  provide(): any {
    return {
      quoteFeatureEnabled: Vue.observable(this.quoteFeatureEnabled)
    };
  }
});
