import { IAddress } from "@/types";
import { get } from "lodash";

export function stringifyAddress(riskAddress: IAddress): string {
  const houseNumber = get(riskAddress, "houseNumber", "");
  const streetName = get(riskAddress, "streetName", "");
  const streetType = get(riskAddress, "streetType", "");
  const zipCode = get(riskAddress, "zipCode", "");
  const city = get(riskAddress, "city", "");
  const state = get(riskAddress, "state", "");
  const streetDirection = get(riskAddress, "streetDirection", "");

  const address = `${houseNumber} ${streetDirection} ${streetName} ${streetType} ${city}, ${state} ${zipCode}`;
  return address.replace(/ +(?= )/g, "");
}
