import { IUserModel } from "@/types";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      liveChatLoading: false,
      liveChatIsActive: false
    };
  },
  methods: {
    setUpChatLive(user: Partial<IUserModel>, isFaq: boolean = false): void {
      if (!user && !isFaq) return;
      if (user?.role === "admin") return;
      window.__lc = window.__lc || {};
      window.__lc.license = 13537521;
      ((n: any, t: any, c: any) => {
        function i(n: any) {
          return e._h ? e._h.apply(null, n) : e._q.push(n);
        }
        const e: any = {
          _q: [],
          _h: null,
          _v: "2.0",
          on: function(...args: any[]) {
            i(["on", c.call(args)]);
          },
          once: function(...args: any[]) {
            i(["once", c.call(args)]);
          },
          off: function(...args: any[]) {
            i(["off", c.call(args)]);
          },
          get: function(...args: any[]) {
            if (!e._h)
              throw new Error(
                "[LiveChatWidget] You can't use getters before load."
              );
            return i(["get", c.call(args)]);
          },
          call: function(...args: any[]) {
            i(["call", c.call(args)]);
          },
          init: function() {
            const n = t.createElement("script");
            (n.async = !0),
              (n.type = "text/javascript"),
              (n.src = "https://cdn.livechatinc.com/tracking.js"),
              t.head.appendChild(n);
          }
        };
        !n.__lc.asyncInit && e.init(),
          (n.LiveChatWidget = n.LiveChatWidget || e);
        const liveChat = n.LiveChatWidget;
        this.liveChatLoading = true;
        liveChat.on("ready", () => {
          const {
            firstName = "",
            lastName = "",
            role = "",
            agentCode = ""
          } = user;
          const userDetails = `${firstName} ${lastName}, ${role.toLocaleUpperCase()}${
            (user as any)?.agencyData?.agencyName
              ? ` of ${(user as any).agencyData.agencyName}`
              : ""
          } (${agentCode})`;
          this.setUpUserForChat(`${userDetails}`, user.email || "");
          this.liveChatLoading = false;
          this.liveChatIsActive = true;
        });
      })(window, document, [].slice);
    },
    async setUpUserForChat(userName: string, userEmail: string) {
      const lc = window.LiveChatWidget;
      lc.call("set_session_variables", {
        name: userName,
        email: userEmail
      });
      lc.call("hide_greeting");
      lc.call("set_customer_name", userName);
      lc.call("set_customer_email", userEmail);
    },
    destroyChat() {
      const lc = window.LiveChatWidget;
      if (lc) {
        lc.call("destroy");
      }
    }
  }
});
