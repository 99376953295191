
import Vue from "vue";
import { getFieldValue, shouldShowField } from "./Helpers";
import { pick, has, get } from "lodash";
import { elementId } from "@/helpers";
import CheckBoxGroup from "./Components/CheckBoxGroup.vue";
import CheckBox from "./Components/CheckBox.vue";
import RadioButtonGroup from "./Components/RadioButtonGroup.vue";
import CheckBoxGroupBlock from "./Components/CheckBoxGroupBlock.vue";
import CustomSelectField from "./Components/CustomSelectField.vue";
import AddressViewer from "./Components/AddressViewer.vue";
import CustomSelectFieldWithInput from "./Components/CustomSelectFieldWithInput.vue";
import CustomTaxIdField from "./Components/CustomTaxIdField.vue";
import TaxIdField from "../FormFields/TaxIdField.vue";
import UserPhysicalAddressFields from "@/components/User/UserPhysicalAddressFields.vue";
import SelectSearchAgencies from "@/components/FormFields/SelectSearchAgencies.vue";
import SelectSearchUnderwriter from "@/components/FormFields/SelectSearchUnderwriter.vue";
import SelectSearchUsers from "@/components/FormFields/SelectSearchUsers.vue";
import CustomDataView from "./Components/CustomDataView.vue";
import CustomColorSelect from "./Components/CustomColorSelect.vue";
import PermissionsEditor from "@/components/User/PermissionsEditor.vue";
import CustomDisabledTextFieldWithInput from "./Components/CustomDisabledTextFieldWithInput.vue";
import CustomRoofStructureField from "./Components/CustomRoofStructureField.vue";
import CustomFormTypesField from "./Components/CustomFormTypesField.vue";
import CustomCompanyOverrides from "./Components/CustomCompanyOverrides.vue";
import CustomCompanyOverridesDF from "./Components/CustomCompanyOverridesDF.vue";
import SelectSearchAdjusterFirms from "@/components/FormFields/SelectSearchAdjusterFirms.vue";
import SelectSearchGbsHelps from "../FormFields/SelectSearchGbsHelps.vue";

export default Vue.extend({
  name: "FormBlockRendererFieldBlock",
  inheritAttrs: false,
  components: {},
  props: {
    schema: {
      type: Object,
      required: false,
      default: () => {}
    },
    formValues: {
      type: Object,
      required: true,
      default: () => ({})
    },
    formValueDefaults: {
      type: Object,
      required: false,
      default: () => ({})
    },
    dynamicOptions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    formFieldChangeHandler(value: any) {
      const { key } = this.schema.properties;
      this.$emit("formFieldChanged", { key, value });
    },
    formFieldVisibleChangeHandler(value: any) {
      const { key } = this.schema.properties;
      this.$emit("formFieldBlur", { key, value });
      this.$emit("formFieldChanged", { key, value });
      this.$nextTick(() => {
        this.$emit("formChangedVisibly", { key, value });
      });
    },
    formFieldBlurHandler() {
      const { key } = this.schema.properties;
      this.$emit("formFieldBlur", { key });
    },
    formFieldFocusHandler() {
      const { key } = this.schema.properties;
      this.$emit("formFieldFocus", { key });
    },
    formFieldKeyDownEnterHandler() {
      const { key } = this.schema.properties;
      this.$emit("formFieldKeyDownEnter", { key });
    },
    formFieldClickFieldArea(area: string) {
      const { key } = this.schema.properties;
      this.$emit("clickFieldArea", { key, area });
    },
    showField(): boolean {
      // showField had to be a method here because updates
      // to formValues was not triggering a computed property
      const { schema, formValues } = this;
      return shouldShowField(schema, formValues);
    },
    getcustomValidator(): any {
      const customValidator = this.propsWithDefaults.validations.find(
        (validation: any) => validation.method === "custom"
      );
      if (customValidator) return customValidator.customValidator;
      return undefined;
    }
  },
  computed: {
    formFieldValue(): any {
      return getFieldValue(this.schema, this.formValues);
    },
    getFieldComponent(): any {
      const { element, properties } = this.schema as any;
      const { type } = properties;
      if (element !== "field") return "div";
      switch (type) {
        case "text":
        case "email":
        case "password":
        case "textarea":
        case "number":
        case "phone":
          return "TextField";
        case "currency":
          return "IACurrencyInput";
        case "date":
          return "DatePicker";
        case "custom-date":
          return "CustomDatePicker";
        case "verifiable-phone":
          return "VerifiablePhoneField";
        case "taxId":
          return CustomTaxIdField;
        case "taxId2":
          return TaxIdField;
        case "select":
          return CustomSelectField;
        case "custom-select-field-with-input":
          return CustomSelectFieldWithInput;
        case "select-search-agencies":
          return SelectSearchAgencies;
        case "select-search-underwriter":
          return SelectSearchUnderwriter;
        case "select-search-users":
          return SelectSearchUsers;
        case "address":
          return UserPhysicalAddressFields;
        case "address_viewer":
          return AddressViewer;
        case "check_box_group":
          return CheckBoxGroup;
        case "check_box_group_block":
          return CheckBoxGroupBlock;
        case "radio_button_group":
          return RadioButtonGroup;
        case "checkbox":
          return CheckBox;
        case "dataview":
          return CustomDataView;
        case "color-select":
          return CustomColorSelect;
        case "permissions-editor":
          return PermissionsEditor;
        case "custom-disabled-text-field-with-input":
          return CustomDisabledTextFieldWithInput;
        case "custom-roof-structure-field":
          return CustomRoofStructureField;
        case "custom-form-types-field":
          return CustomFormTypesField;
        case "company-overrides":
          return CustomCompanyOverrides;
        case "company-overrides-df":
          return CustomCompanyOverridesDF;
        case "select-search-adjuster-firms":
          return SelectSearchAdjusterFirms;
        case "select-search-gbs-helps":
          return SelectSearchGbsHelps;
        default:
          return "div";
      }
    },
    propsWithDefaults(): any {
      const items = [
        "id",
        "type",
        "label",
        "description",
        "descriptionHTML",
        "placeholder",
        "required",
        "preamble",
        "options",
        "validations",
        "phoneType",
        "verified",
        "isSettings",
        "isNew",
        "savedValue",
        "addressType",
        "isPOBOXMailingAddress",
        "disabled",
        "readonly",
        "loading",
        "multiple",
        "isPOBOXMailingAddress",
        "pickerOptions",
        "validateAddress",
        "isInline",
        "allowSyncingWithPhysicalAddress",
        "multiple",
        "filterable",
        "allowCreate",
        "allowFirstOption",
        "fixedLabels",
        "roles",
        "formatDate",
        "clearable",
        "showMaskSelector",
        "popoverContentTitle",
        "popoverContent",
        "popoverText",
        "emitRadioButtonChangeEvent",
        "showPopover",
        "inputTextClass",
        "useFormChangedVisibly",
        "labelIcon",
        "labelIconTooltip",
        "minYear",
        "maxYear",
        "disableState",
        "lineOfBusiness",
        "limitToTexas",
        "autocomplete",
        "information",
        "gbsSearchType",
        "useLabelAsValue",
        "showOnlyAgencyCodes",
        "useAgencyCodeAsValue",
        "rows"
      ];
      const { id } = this.schema;
      const elId = elementId(id);
      const defaults: any = Object.assign(
        {
          id: `field_${elId}`,
          ref: `field_${elId}`,
          autocomplete: `field_${elId}`,
          type: "text",
          label: "",
          description: "",
          descriptionHTML: "",
          placeholder: "",
          required: false,
          loading: false,
          disabled: this.disabled || false,
          validations: [],
          options: [],
          preamble: "",
          multiple: false,
          clearable: false,
          filterable: false,
          allowCreate: false,
          allowFirstOption: false,
          fixedLabels: false,
          formatDate: false,
          roles: [],
          savedValue: "",
          labelIcon: "",
          labelIconTooltip: "",
          minYear: "",
          maxYear: "",
          disableState: false,
          lineOfBusiness: "",
          limitToTexas: true,
          gbsSearchType: "county",
          useLabelAsValue: false,
          information: {
            title: "",
            text: ""
          },
          showOnlyAgencyCodes: false,
          useAgencyCodeAsValue: false,
          rows: 0
        },
        pick(this.schema.properties, items)
      );

      if (has(this.dynamicOptions, this.schema.properties.key)) {
        defaults.options = get(this.dynamicOptions, this.schema.properties.key);
      }

      if (Array.isArray(defaults.label)) {
        const [namespace, text] = defaults.label;
        defaults.label = this.__getText(namespace, text);
      }

      if (Array.isArray(defaults.placeholder)) {
        const [namespace, text] = defaults.placeholder;
        defaults.placeholder = this.__getText(namespace, text);
      }

      if (defaults.label && typeof defaults.label === "function") {
        defaults.label = defaults.label(this.formValues);
      }

      return defaults;
    },
    showingField(): boolean {
      return this.showField();
    }
  },
  watch: {
    showingField: {
      handler(showing: boolean) {
        if (!showing) {
          if (!Object.keys(this.formValueDefaults).length) return;
          const { key } = this.schema.properties;
          const value = get(this.formValueDefaults, key);
          this.$emit("formFieldChanged", { key, value });
        }
      }
    }
  }
});
